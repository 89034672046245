<template>
   <label :class="[ { 'has-error': hasError }, { 'topbar-dropdown': isTopbarElement } ]">
      <span
         v-if="label"
         :class="[ { 'required' : required }, 'label' ]"
      >
         {{ $t(label) }}
      </span>
      <div
         v-if="hasValuesLoaded"
         ref="dropdown"
         :class="[
            'dropdown',
            'input',
            { 'is-readonly' : readonly }
         ]"
      >
         <button
            ref="frontendButton"
            type="button"
            :aria-expanded="showDropdown"
            :class="[ 'dropdown-display', { 'not-selected': (!hasSelected && !getSelectionObject[valueKey]) || icon } ]"
            tabindex="0"
            aria-haspopup="listbox"
            @click.prevent="ToogleDropdown()"
         >
            <div v-if="!hasSelected && !noIcons">
               <icon
                  v-if="icon"
                  :icon="['fal', icon]"
               />
               <icon
                  v-else-if="getSelectionObject.icon && !smallIcons"
                  :icon="['fal', getSelectionObject.icon]"
               />
               <i
                  v-else
                  class="icon"
                  :style="{ backgroundImage: 'url(' + getSelectionObject.flag_image.url || getSelectionObject.flag_image + ')' }"
               />
               <span v-if="getSelectionObject.label">{{ $t(getSelectionObject.label) }}</span>
               <span v-else-if="!CheckTranslation(getSelectionObject)">{{ getSelectionObject[labelKey] }}</span>
               <span v-else>{{ DynTranslate(labelKey, getSelectionObject) }}</span>
            </div>
            <div v-else>
               <icon
                  v-if="icon"
                  :icon="['fal', icon]"
               />
               <i
                  v-else-if="getSelectionObject.flag_image"
                  class="icon"
                  :style="{ backgroundImage: 'url(' + getSelectionObject.flag_image.url || getSelectionObject.flag_image + ')' }"
               />
               <span v-if="getSelectionObject.label">{{ $t(getSelectionObject.label) }}</span>
               <span v-else-if="!CheckTranslation(getSelectionObject)">{{ getSelectionObject[labelKey] }}</span>
               <span v-else>{{ DynTranslate(labelKey, getSelectionObject) }}</span>
            </div>
         </button>
         <div
            v-if="hasError && !readonly"
            class="error-msg full-width"
         >
            <span v-if="!IsEmpty(message[0].attribute)">{{ $t(message[0].msg, { attr: message[0].attribute }) }}</span>
            <span v-else>{{ $t(message[0].msg) }}</span>
         </div>
         <ul
            v-if="!readonly"
            v-show="showDropdown"
            ref="frontendOptions"
            v-closable="{ exclude: ['frontendButton'], handler: 'OnClose' }"
            class="dropdown-options"
            role="listbox"
         >
            <li
               v-for="(item, index) in filteredSelection"
               :key="index"
               ref="option"
               class="option"
               role="option"
               :tabindex="showDropdown ? '0' : '-1'"
               @click.prevent="UpdateOption(item, index)"
               @keyup.enter="UpdateOption(item, index)"
            >
               <i
                  v-if="item.flag_image"
                  class="icon"
                  :style="{ backgroundImage: 'url(' + item.flag_image.url || item.flag_image + ')' }"
               />
               <span v-if="item.label">{{ $t(item.label) }}</span>
               <span v-else-if="!CheckTranslation(item)">{{ item[labelKey] }}</span>
               <span v-else>{{ DynTranslate(labelKey, item) }}</span>
            </li>
         </ul>
      </div>
      <div
         v-else
         class="loading-input"
      />
   </label>
</template>

<script>
   import { IsEmpty, DynTranslate, CheckTranslation } from '../../functions'

   export default {
      inheritAttrs: false,
      model: {
         event: 'change'
      },
      props: {
         valueKey: {
            type: String,
            default: 'id',
            required: false
         },
         labelKey: {
            type: String,
            default: 'name',
            required: false
         },
         isTopbarElement: {
            type: Boolean,
            default: false,
            required: false
         },
         label: {
            type: String,
            default: null,
            required: false
         },
         init: {
            type: Object,
            required: true
         },
         data: {
            type: Array,
            required: true
         },
         noIcons: {
            type: Boolean,
            default: false,
            required: false
         },
         smallIcons: {
            type: Boolean,
            default: false,
            required: false
         },
         required: {
            type: Boolean,
            default: false,
            required: false
         },
         message: {
            type: Array,
            default: null,
            required: false
         },
         error: {
            type: Boolean,
            default: false,
            required: false
         },
         readonly: {
            type: Boolean,
            default: false,
            required: false
         },
         icon: {
            type: String,
            default: null,
            required: false
         }
      },
      data() {
         return {
            showDropdown: false,
            hasSelected: false,
            selectedElement: null,
            localError: null
         }
      },
      computed: {
         hasValuesLoaded() {
            return (!IsEmpty(this.data) && !IsEmpty(this.init))
         },
         getSelectionObject() {
            return !IsEmpty(this.selectedElement) ? this.selectedElement : this.init
         },
         filteredSelection() {
            let totalSelection = this.data
            let tempSelection = []
            const selectedValue = this.$attrs.value

            for (let i = 0; i < totalSelection.length; i++) {
               if (totalSelection[i][this.valueKey] !== selectedValue) {
                  tempSelection.push(totalSelection[i])
               }
            }
            return tempSelection
         },
         hasError() {
            return (this.localError && this.error)
         }
      },
      watch: {
         'getSelectionObject': function() {
            this.localError = false
         },
         'error': function(val) {
            if (val !== this.localError) {
               this.localError = val
            }
         }
      },
      mounted() {
         if (document.addEventListener) {
            window.addEventListener('scroll', this.CloseTopbarDropdown, false)
         } else {
            document.attachEvent('onscroll', this.CloseTopbarDropdown)
         }
      },
      created() {
         this.IsEmpty = IsEmpty
         this.DynTranslate = DynTranslate
         this.CheckTranslation = CheckTranslation
      },
      methods: {
         UpdateOption(option) {
            this.$emit('change', option[this.valueKey])
            this.selectedElement = option
            this.hasSelected = true
            this.OnClose()
         },
         ToogleDropdown() {
            this.showDropdown = !this.showDropdown
         },
         OnClose() {
            this.showDropdown = false
         },
         CloseTopbarDropdown() {
            if (this.isTopbarElement) {
               this.OnClose()
            }
         }
      }
   }
</script>

<style lang="scss" scoped>
   @import '~@/variables';
   @import '~@/functions';
   @import '~@/snippets';

   .topbar-dropdown {
      .dropdown {
         box-shadow: unset;
         border-radius: unset;

         .dropdown-display {
            padding: 4px 26px 4px 8px;
            background: transparent;
            border-radius: 0;
            box-shadow: none;
            height: calc(#{$header-topbar-height} - 1px);
            border: none;
            @include smooth-ease();

            &:hover {
               background: var(--header-hover-color);
            }

            &:focus {
               span {
                  font-weight: bold;
               }
            }

            &:hover, &:focus {
               span {
                  color: $footer-link-color;
               }
            }

            &:after {
               top: $medium-gap;
               right: $small-gap;
            }

            &:before {
               left: $small-gap;
               margin-top: 4px;
            }

            span {
               color: var(--link-color);
               text-decoration: underline;
               font-size: $smaller-font-size;
            }

            @media screen and (max-width: $breakpoint-mobile) {
               span {
                  display: none;
               }
            }
         }

         .dropdown-options {
            min-width: 130px;
            left: 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: var(--background-accent-color-alt);
            border-radius: 0;
            top: $header-topbar-height;
            border-width: 0 1px 1px;
            box-shadow: none;

            li.option {
               padding: $small-gap;
               width: 100%;

               &:hover, &:focus {
                  background: var(--header-hover-color);

                  span {
                     color: $footer-link-color;
                  }
               }

               &:focus {
                  @include input-outline();

                  span {
                     font-weight: bold;
                  }
               }

               span {
                  color: var(--link-color);
                  text-decoration: underline;
                  font-size: $smaller-font-size;
               }

               @media screen and (max-width: $breakpoint-mobile) {
                  span {
                     display: none;
                  }
               }
            }
         }
      }
   }

   .has-error {
      margin: 0;

      .dropdown .dropdown-display {
         border-color: var(--danger-color);
      }
   }

   .dropdown {
      position: relative;
      box-shadow: $main-box-shadow;
      @include rounded($main-button-rounding);

      &.is-readonly {
         box-shadow: unset;
         border-radius: none;

         .dropdown-display {
            border: none;
            background: none;
            cursor: default;

            &:after {
               display: none;
            }
         }
      }

      &.active {
         .dropdown-display {
            @include rounded(3px 3px 0 0);
         }

         .dropdown-options {
            display: block;
         }
      }

      .icon {
         width: 16px;
         height: 11px;
         background-repeat: no-repeat;
         background-size: contain;
         margin: 0 $small-gap 0 0;
         left: 12px;
         display: inline-block;
      }

      &.active.reverse {
         .dropdown-display {
            @include rounded(0 0 3px 3px);
         }

         .dropdown-options {
            @include rounded(3px 3px 0 0);
            top: auto;
            bottom: 100%;
            margin-bottom: -7px;
            box-shadow: 2px 0 0 rgb(217, 217, 217);
            border-width: 0 0 1px;
         }
      }

      .dropdown-display {
         @include input-styling();
         text-align: left;
         cursor: pointer;
         color: var(--font-color);
         text-transform: initial;
         position: relative;

         &.not-selected {
            padding: 10px 12px 10px 44px;
         }

         .fa-icon {
            left: 0;
            top: 0;
         }

         &.icon {
            padding: 10px 12px 10px 35px;
         }

         &:hover {
            border-color: var(--link-color);
         }

         &:focus {
            border-color: var(--accent-color);
         }

         &:after {
            @include square(0);
            border: 5px solid transparent;
            border-color: var(--header-font-color) transparent transparent transparent;
            top: 20px;
            right: 15px;
         }
      }

      .dropdown-options {
         @include rounded(0 0 3px 3px);
         margin: 0;
         text-transform: capitalize;
         position: absolute;
         width: 100%;
         z-index: 1000;
         background: var(--background-accent-color-alt);
         box-shadow: var(--section-box-shadow);
         border: var(--section-border);
         //border: solid rgba(0, 0, 0, 0.05);
         border-width: 1px 0 0;
         //box-shadow: 2px 2px 0 rgb(217, 217, 217);
         top: $input-height;

         li.option {
            @include smooth-ease(.15s);
            padding: 10px 12px;
            cursor: pointer;
            font-weight: 400;
            text-transform: capitalize;
            font-size: 0px;

            span {
               display: inline-block;
               font-size: 15px;
            }

            &.icon {
               padding: 10px 12px 10px 35px;
            }

            &:hover {
               background: rgba(0, 0, 0, 0.05);
            }
         }
      }
   }
</style>