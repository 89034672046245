// Pages
const Home = () => import('../components/layouts/page/Home.vue')
const Contact = () => import('../components/layouts/page/Contact.vue')
const PrivacyPolicy = () => import('../components/layouts/page/PrivacyPolicy.vue')
const About = () => import('../components/layouts/page/About.vue')
const Testimonials = () => import('../components/layouts/page/Testimonials.vue')
const Packages = () => import('../components/layouts/page/Packages.vue')
const FAQ = () => import('../components/layouts/page/FAQ.vue')

// Single layouts
const Category = () => import('../components/layouts/page/Category.vue')
const Country = () => import('../components/layouts/page/Country.vue')
const Property = () => import('../components/layouts/single/Property.vue')

// Auth
const Login = () => import('../components/layouts/auth/Login.vue')
const ForgotPassword = () => import('../components/layouts/auth/ForgotPassword.vue')
const ResetPasswordForm = () => import('../components/layouts/auth/ResetPasswordForm.vue')
const Register = () => import('../components/layouts/auth/Register.vue')

// Error pages
const Error403 = () => import('../components/layouts/error/403.vue')
const Error404 = () => import('../components/layouts/error/404.vue')
const Error500 = () => import('../components/layouts/error/500.vue')

// Account
const UserDashboard = () => import('../components/layouts/dashboard/Dashboard.vue')
const Qualifications = () => import('../components/layouts/dashboard/Qualifications.vue')
const SelfService = () => import('../components/layouts/dashboard/SelfService.vue')
const SelfServiceCreate = () => import('../components/layouts/dashboard/SelfServiceCreate')
const AccountSettings = () => import('../components/layouts/dashboard/AccountSettings.vue')
const TwoFAEnable = () => import('../components/layouts/dashboard/2fa/Enable2FA.vue')
const TwoFADisable = () => import('../components/layouts/dashboard/2fa/Disable2FA.vue')
const Notifications = () => import('../components/layouts/dashboard/Notifications.vue')
const Billing = () => import('../components/layouts/dashboard/Billing.vue')
const ExtraAddons = () => import('../components/layouts/dashboard/ExtraAddons')
const SelfServiceDisclaimer = () => import('../components/layouts/dashboard/SelfServiceDisclaimer')
const SelfServicePropertyQualification = () => import('../components/layouts/dashboard/SelfServicePropertyQualification')
const KYC = () => import('../components/layouts/dashboard/KYC')
const Bids = () => import('../components/layouts/dashboard/Bids')

// Admin
const AdminDashboard = () => import('../components/layouts/admin/Dashboard.vue')

// Admin account settings
const AdminAccountSettings = () => import('../components/layouts/admin/view/AccountSettings.vue')
const AdminAccount2FAEnable = () => import('../components/layouts/admin/view/2fa/Enable2FA.vue')
const AdminAccount2FADisable = () => import('../components/layouts/admin/view/2fa/Disable2FA.vue')

// Admin CRUD Views
const AdminViewMembers = () => import('../components/layouts/admin/view/Members.vue')
const AdminViewProperties = () => import('../components/layouts/admin/view/Properties.vue')
const AdminViewCategories = () => import('../components/layouts/admin/view/Categories.vue')
const AdminViewCountries = () => import('../components/layouts/admin/view/Countries.vue')
const AdminViewCurrencies = () => import('../components/layouts/admin/view/Currencies.vue')
const AdminViewExcludedDates = () => import('../components/layouts/admin/view/ExcludedDates.vue')
const AdminViewBids = () => import('../components/layouts/admin/view/Bids.vue')
const AdminViewPropertyTours = () => import('../components/layouts/admin/view/PropertyTours.vue')
const AdminViewProspects = () => import('../components/layouts/admin/view/Prospects.vue')
const AdminViewAddons = () => import('../components/layouts/admin/view/Addons.vue')
const AdminViewAddonBundles = () => import('../components/layouts/admin/view/AddonBundles.vue')
const AdminViewQualifications = () => import('../components/layouts/admin/view/PropertyQualifications.vue')
const AdminViewLanguages = () => import('../components/layouts/admin/view/Languages.vue')
const AdminWebsiteSettings = () => import('../components/layouts/admin/view/WebsiteSettings.vue')
const AdminViewFAQs = () => import('../components/layouts/admin/view/FAQs.vue')
const AdminViewTeamMembers = () => import('../components/layouts/admin/view/TeamMembers.vue')
const AdminViewTestimonials = () => import('../components/layouts/admin/view/Testimonials.vue')
const AdminViewTranslations = () => import('../components/layouts/admin/view/Translations.vue')
const AdminViewAuctions = () => import('../components/layouts/admin/view/Auctions.vue')

// Admin CRUD Single
const AdminEditMember = () => import('../components/layouts/admin/single/Member.vue')
const AdminEditMemberPassword = () => import('../components/layouts/admin/single/MemberPassword.vue')
const AdminEditProperty = () => import('../components/layouts/admin/single/Property.vue')
const AdminSingleCategory = () => import('../components/layouts/admin/single/Category.vue')
const AdminEditCountry = () => import('../components/layouts/admin/single/Country.vue')
const AdminEditCurrency = () => import('../components/layouts/admin/single/Currency.vue')
const AdminEditExcludedDate = () => import('../components/layouts/admin/single/ExcludedDate.vue')
const AdminEditAddon = () => import('../components/layouts/admin/single/Addon.vue')
const AdminEditAddonBundle = () => import('../components/layouts/admin/single/AddonBundle.vue')
const AdminEditPropertyTour = () => import('../components/layouts/admin/single/PropertyTour.vue')
const AdminEditProspect = () => import('../components/layouts/admin/single/Prospect.vue')
const AdminEditQualification = () => import('../components/layouts/admin/single/PropertyQualification.vue')
const AdminEditBid = () => import('../components/layouts/admin/single/Bid.vue')
const AdminEditLanguage = () => import('../components/layouts/admin/single/Language.vue')
const AdminEditFAQPage = () => import('../components/layouts/admin/single/pages/FAQ.vue')
const AdminSingleAboutPage = () => import('../components/layouts/admin/single/pages/About.vue')
const AdminSingleTestimonialPage = () => import('../components/layouts/admin/single/pages/Testimonial.vue')
const AdminSinglePrivacyPage = () => import('../components/layouts/admin/single/pages/Privacy.vue')
const AdminSingleContactPage = () => import('../components/layouts/admin/single/pages/Contact.vue')
const AdminSinglePackagesPage = () => import('../components/layouts/admin/single/pages/Packages.vue')
const AdminSinglePropertyHistory = () => import('../components/layouts/admin/single/History.vue')
const AdminSingleTeamMemberPage = () => import('../components/layouts/admin/single/TeamMember.vue')
const AdminSingleTestimonial = () => import('../components/layouts/admin/single/Testimonial.vue')
const AdminSingleAuction = () => import('../components/layouts/admin/single/Auction.vue')

const routes = [
   {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/403',
      component: Error403,
      name: 'error-403',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/404',
      component: Error404,
      name: 'error-404',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/500',
      component: Error500,
      name: 'error-500',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/contact',
      component: Contact,
      name: 'contact',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/about-us',
      component: About,
      name: 'about',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/testimonials',
      component: Testimonials,
      name: 'testimonials',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/packages',
      component: Packages,
      name: 'packages',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/privacy-policy',
      component: PrivacyPolicy,
      name: 'privacy-policy',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/faq/:id/:slug?',
      component: FAQ,
      name: 'faq',
      meta: {
         auth: undefined
      }
   },
   {
      path: '/property/:id/:slug?/:page?',
      component: Property,
      name: 'property',
      meta: {
         auth: undefined,
         transparent_header: true
      }
   },
   {
      path: '/category/:id/:slug?',
      component: Category,
      name: 'category',
      meta: {
         auth: undefined,
         transparent_header: true
      }
   },
   {
      path: '/country/:id/:slug?',
      component: Country,
      name: 'country',
      meta: {
         auth: undefined,
         transparent_header: true
      }
   },
   {
      path: '/register',
      component: Register,
      name: 'register',
      meta: {
         auth: false
      }
   },
   {
      path: '/login',
      component: Login,
      name: 'login',
      meta: {
         auth: false
      }
   },
   {
      path: '/dashboard',
      component: UserDashboard,
      name: 'dashboard',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/qualifications',
      component: Qualifications,
      name: 'qualifications',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/billing',
      component: Billing,
      name: 'dashboard-billing',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/self-service',
      component: SelfService,
      name: 'self-service',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/self-service/addons/:id',
      component: ExtraAddons,
      name: 'self-service-extra-addons',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/know-your-customer/:id?',
      component: KYC,
      name: 'know-your-customer',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/self-service-create/:type',
      component: SelfServiceCreate,
      name: 'self-service-create',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/self-service-property-qualification/:id?',
      component:  SelfServicePropertyQualification,
      name: 'self-service-property-qualification',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/self-service-disclaimer',
      component: SelfServiceDisclaimer,
      name: 'self-service-disclaimer',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/self-service/bids/:id?',
      component: Bids,
      name: 'bids',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/settings',
      component: AccountSettings,
      name: 'account-settings',
      meta: {
         auth: true
      }
   },
   {
      path: '/dashboard/notifications',
      component: Notifications,
      name: 'notifications',
      meta: {
         auth: true
      }
   },
   {
      path: '/2fa/enable',
      component: TwoFAEnable,
      name: 'two-factor-authentication-enable',
      meta: {
         auth: true
      }
   },
   {
      path: '/2fa/disable',
      component: TwoFADisable,
      name: 'two-factor-authentication-disable',
      meta: {
         auth: true
      }
   },
   {
      path: '/reset-password',
      component: ForgotPassword,
      name: 'reset-password',
      meta: {
         auth: false
      }
   },
   {
      path: '/reset-password/:token',
      component: ResetPasswordForm,
      name: 'reset-password-form',
      meta: {
         auth: false
      }
   },
   {
      path: '/admin',
      component: AdminDashboard,
      name: 'admin-dashboard',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/personal/settings',
      component: AdminAccountSettings,
      name: 'admin-account-settings',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/website/settings',
      component: AdminWebsiteSettings,
      name: 'admin-website-settings',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/personal/settings/2fa/enable',
      component: AdminAccount2FAEnable,
      name: 'admin-account-settings-2fa-enable',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/personal/settings/2fa/disable',
      component: AdminAccount2FADisable,
      name: 'admin-account-settings-2fa-disable',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/categories',
      component: AdminViewCategories,
      name: 'admin-view-categories',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/testimonials',
      component: AdminViewTestimonials,
      name: 'admin-view-testimonials',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/website/translations',
      component: AdminViewTranslations,
      name: 'admin-view-translations',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/website/auctions',
      component: AdminViewAuctions,
      name: 'admin-view-auctions',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/members',
      component: AdminViewMembers,
      name: 'admin-view-members',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties',
      component: AdminViewProperties,
      name: 'admin-view-properties',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/category/:type/:id?',
      component: AdminSingleCategory,
      name: 'admin-single-category',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/property-tour/:type/:id?',
      component: AdminEditPropertyTour,
      name: 'admin-single-property-tour',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/bid/:type/:id?',
      component: AdminEditBid,
      name: 'admin-single-bid',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/countries',
      component: AdminViewCountries,
      name: 'admin-view-countries',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/country/:type/:id?',
      component: AdminEditCountry,
      name: 'admin-single-country',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/excluded-date/:type/:id?',
      component: AdminEditExcludedDate,
      name: 'admin-single-excluded-date',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/team-member/:type/:id?',
      component: AdminSingleTeamMemberPage,
      name: 'admin-single-team-member',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/testimonial/:type/:id?',
      component: AdminSingleTestimonial,
      name: 'admin-single-testimonial',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/auction/:type/:id?',
      component: AdminSingleAuction,
      name: 'admin-single-auction',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/currencies',
      component: AdminViewCurrencies,
      name: 'admin-view-currencies',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/excluded-dates',
      component: AdminViewExcludedDates,
      name: 'admin-view-excluded-dates',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/currency/:type/:id?',
      component: AdminEditCurrency,
      name: 'admin-single-currency',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/website/language/:type/:id?',
      component: AdminEditLanguage,
      name: 'admin-single-language',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/addon/:type/:id?',
      component: AdminEditAddon,
      name: 'admin-single-addon',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/prospect/:type/:id?',
      component: AdminEditProspect,
      name: 'admin-single-prospect',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/addon-bundle/:type/:id?',
      component: AdminEditAddonBundle,
      name: 'admin-single-addon-bundle',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/qualification/:type/:id?',
      component: AdminEditQualification,
      name: 'admin-single-qualification',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/members/member/:type/:id?',
      component: AdminEditMember,
      name: 'admin-single-member',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/members/member-password/:id?',
      component: AdminEditMemberPassword,
      name: 'admin-single-member-password',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/property/history/:id',
      component: AdminSinglePropertyHistory,
      name: 'admin-single-history',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/property/:type/:id?',
      component: AdminEditProperty,
      name: 'admin-single-property',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/bids',
      component: AdminViewBids,
      name: 'admin-view-bids',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/property-tours',
      component: AdminViewPropertyTours,
      name: 'admin-view-property-tours',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/prospects',
      component: AdminViewProspects,
      name: 'admin-view-prospects',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/addons',
      component: AdminViewAddons,
      name: 'admin-view-addons',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/taxonomy/addon-bundles',
      component: AdminViewAddonBundles,
      name: 'admin-view-addon-bundles',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/properties/qualifications',
      component: AdminViewQualifications,
      name: 'admin-view-qualifications',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/website/languages',
      component: AdminViewLanguages,
      name: 'admin-view-languages',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/faqs',
      component: AdminViewFAQs,
      name: 'admin-view-faqs',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/team-members',
      component: AdminViewTeamMembers,
      name: 'admin-view-team-members',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/about',
      component: AdminSingleAboutPage,
      name: 'admin-page-about',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/testimonial',
      component: AdminSingleTestimonialPage,
      name: 'admin-page-testimonial',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/faq/:type/:id?',
      component: AdminEditFAQPage,
      name: 'admin-single-faq',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/privacy-policy',
      component: AdminSinglePrivacyPage,
      name: 'admin-page-privacy',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/contact',
      component: AdminSingleContactPage,
      name: 'admin-page-contact',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   },
   {
      path: '/admin/pages/packages',
      component: AdminSinglePackagesPage,
      name: 'admin-page-packages',
      meta: {
         auth: {
            roles: 2,
            redirect: {
               name: 'login'
            },
            forbiddenRedirect: '/403'
         }
      }
   }
]

export default routes
