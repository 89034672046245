<template>
   <footer>
      <div id="main-footer">
         <ul
            id="footer-content"
            class="container"
         >
            <li>
               <h4>{{ $t('message.contact') }}</h4>
               <table class="no-style">
                  <tbody>
                     <tr>
                        <td>
                           <icon :icon="['fal', 'id-card']"/>
                        </td>
                        <td>
                           <ul>
                              <li>
                                 <span>{{ $t('message.vatNumber') }}</span>
                              </li>
                              <li>
                                 <span>{{ vat }}</span>
                              </li>
                           </ul>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <icon :icon="['fal', 'building']"/>
                        </td>
                        <td>
                           <ul>
                              <li>
                                 <span>{{ $t('message.address') }}</span>
                              </li>
                              <li>
                                 <span>{{ Translate('streetName', translations, $i18n.locale) }}</span>
                                 <div>
                                    <span>{{ postal }}</span>
                                    <span>{{ Translate('city', translations, $i18n.locale) }},</span>
                                    <span>{{ Translate('countryName', translations, $i18n.locale) }}</span>
                                 </div>
                              </li>
                           </ul>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <icon :icon="['fal', 'phone-office']"/>
                        </td>
                        <td>
                           <ul>
                              <li>
                                 <span>{{ $t('message.phone') }}</span>
                              </li>
                              <li>
                                 <div v-if="GetFlag('da')">
                                    <i
                                       class="flag-icon"
                                       :style="{ backgroundImage: 'url('+GetFlag('da')+')' }"
                                    />
                                    <span>{{ contactPhone }}</span>
                                 </div>
                                 <div v-if="Translate('localPhoneNumber', translations, $i18n.locale)">
                                    <i
                                       class="flag-icon"
                                       :style="{ backgroundImage: 'url('+GetFlag($i18n.locale)+')' }"
                                    />
                                    <span>{{ Translate('localPhoneNumber', translations, $i18n.locale) }}</span>
                                 </div>
                              </li>
                           </ul>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <icon :icon="['fal', 'at']"/>
                        </td>
                        <td>
                           <ul>
                              <li>
                                 <span>{{ $t('message.email') }}</span>
                              </li>
                              <li>
                                 <span>
                                    <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
                                 </span>
                              </li>
                           </ul>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </li>
            <li>
               <h4>{{ $t('message.pages') }}</h4>
               <ul>
                  <li>
                     <router-link
                        tabindex="0"
                        :to="{ name: 'about' }"
                     >
                        <span>{{ $t('message.aboutUsTitle') }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link
                        tabindex="0"
                        :to="{ name: 'privacy-policy' }"
                     >
                        <span>{{ $t('message.privacyPolicyTitle') }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link
                        :to="{ name: 'testimonials' }"
                        tabindex="0"
                     >
                        <span>{{ $t('pages.sidebar.testimonialPage') }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link
                        tabindex="0"
                        :to="{ name: 'contact' }"
                     >
                        <span>{{ $t('message.contact') }}</span>
                     </router-link>
                  </li>
               </ul>
            </li>
            <li>
               <h4>{{ $t('message.awards') }}</h4>
               <img
                  :src="Gazelle2020"
                  title="Gazelle 2020"
                  alt="Gazelle 2020"
               >
               <img
                  :src="Gazelle2021"
                  title="Gazelle 2021"
                  alt="Gazelle 2021"
               >
            </li>
         </ul>
         <div id="logo-footer-bar">
            <logo
               id="footer-logo"
               :clean-logo="true"
               :invert-color="true"
            />
         </div>
      </div>
      <div id="copyright">
         <span>Copyright © {{ printYear }}
            <a
               href="https://truemarketvalue.dk/"
               target="_blank"
               rel="noreferrer noopener"
            >
               {{ $t('message.TrueMarketValue') }}
            </a>
         </span>
         <i class="vertical-rule"/>
         <span>{{ $t('footer.reservedRights') }}.</span>
      </div>
      <alert/>
      <vue-cookie-accept-decline
         :ref="'cookie-wrapper'"
         :element-id="'cookie-wrapper'"
         :debug="false"
         :position="'bottom-left'"
         :type="'floating'"
         :disable-decline="false"
         :transition-name="'slideFromBottom'"
         :show-postpone-button="false"
      >
         <div slot="postponeContent">
            &times;
         </div>
         <div slot="message">
            <span>{{ $t('cookies.msg') }}</span>
            <router-link :to="{ name: 'privacy-policy' }">
               <span> {{ $t('cookies.button') }}</span>
            </router-link>
         </div>
         <div slot="declineContent">
            <span>{{ $t('cookies.decline') }}</span>
         </div>
         <div slot="acceptContent">
            <span>{{ $t('cookies.accept') }}</span>
         </div>
      </vue-cookie-accept-decline>
   </footer>
</template>

<script>
   import { DynTranslate, Translate, AjaxGet, FetchObject } from '../../functions'
   import { mapGetters } from 'vuex'
   import Logo from '../elements/PropLogo'
   import Alert from '../elements/Alert'
   import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
   import Gazelle2020 from '../../../img/gazelle2020-logo.svg'
   import Gazelle2021 from '../../../img/gazelle2021-logo.svg'

   export default {
      components: {
         Logo,
         Alert,
         VueCookieAcceptDecline
      },

      data() {
         return {
            langs: []
         }
      },

      computed: {
         ...mapGetters('settings', [
            'contactEmail',
            'contactPhone',
            'postal',
            'vat',
            'translations'
         ]),
         printYear() {
            return new Date().getFullYear()
         }
      },

      created() {
         this.Gazelle2020 = Gazelle2020
         this.Gazelle2021 = Gazelle2021
         this.DynTranslate = DynTranslate
         this.Translate = Translate
         this.FetchLanguages()
      },

      methods: {
         FetchLanguages() {
            AjaxGet('/global/languages', (resp) => {
               this.langs = resp.data
            })
         },
         GetFlag(locale) {
            return (FetchObject(this.langs, locale, 'locale')).flag_image.url
         }
      }
   }
</script>

<style lang="scss">
   @import '~@/variables';
   @import '~@/functions';

   #cookie-wrapper {
      padding: $medium-gap;
      background: #0b1c2b;
      max-width: 350px;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 0;

      .cookie__floating__wrap {
         .cookie__floating__content {
            max-height: 145px;
         }

         .cookie__floating__buttons {
            @include grid();

            .cookie__floating__buttons__button {
               @include rounded($main-button-rounding);
               padding: 10px 20px;
               background: $main-accent-color-lighter;
               color: $main-white-color;
               box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25) inset, 0 1px 0 rgba(0, 0, 0, 0.65);
               border: none;

               &.cookie__floating__buttons__button--accept {
                  background: $main-success-color;
               }

               &.cookie__floating__buttons__button--decline {
                  background: $main-danger-color;
               }
            }
         }
      }
   }
</style>

<style lang="scss" scoped>
   @import '~@/variables';
   @import '~@/functions';
   @import '~cookie';

   footer {
      color: $main-gray-color;
      border-top: $small-gap solid rgba(0, 0, 0, 0.05);
      margin-top: $large-gap;

      @media screen and (max-width: $breakpoint-laptop) {
         margin-top: $medium-gap;
      }

      @media screen and (max-width: $breakpoint-small-mobile) {
         margin-top: $small-gap;
      }

      a {
         color: $footer-link-color;

         &[rel="noreferrer noopener"]:before {
            right: -6px;
            top: -4px;
            color: $footer-link-color;
            font-size: 8px;
         }

         &:hover {
            color: #bdcfe6;
         }
      }

      #main-footer {
         padding: $large-gap 0 0;
         background: $main-accent-color;

         #footer-content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: $large-gap;
            padding-bottom: $large-gap;

            h4 {
               font-size: $smaller-font-size;
               text-transform: uppercase;
               letter-spacing: 1px;
               color: rgba(255, 255, 255, 0.75);

               &:after {
                  border-bottom: 2px solid $main-accent-color-lighter;
               }
            }

            table {
               tbody {
                  grid-gap: 10px;
                  display: grid;

                  td {
                     vertical-align: top;

                     &:first-of-type {
                        padding-right: 8px;
                     }

                     i:not(.flag-icon) {
                        @include square(25px);
                        font-size: 21px;
                        color: $footer-link-color;
                     }

                     ul li:first-of-type {
                        font-size: 11px;
                        color: rgba(255, 255, 255, 0.5);
                        text-transform: uppercase;
                        font-weight: bold;
                     }
                  }
               }
            }


            #footer-logo {
               @include square(175px);
               display: block;
            }

            @media screen and (max-width: $breakpoint-mobile) {
               grid-template-columns: unset;
            }
         }
      }

      #logo-footer-bar {
         background: rgba(0, 0, 0, 0.15);
         padding: 10px 0;
      }

      #copyright {
         background: $main-accent-color-darker;
         font-size: $smaller-font-size;
         padding: $medium-gap 0;
         color: $main-gray-color;
         text-align: center;

         @media screen and (max-width: $breakpoint-mobile) {
            line-height: 20px;

            span {
               display: block;
            }

            i.vertical-rule {
               display: none;
            }
         }
      }
   }
</style>
