<template>
   <header
      ref="header"
      class="top-of-page"
   >
      <nav id="topbar-navigation">
         <div class="container header-std-layout">
            <div/>
            <div>
               <div id="topbar-navigation-links">
                  <ul v-if="$store.getters['auth/check']">
                     <div
                        id="topbar-auth-user"
                        :title="$t('header.loggedInAs', { username: $store.state.auth.user.email })"
                     >
                        <span>{{ $t('header.welcome', { company: $store.state.auth.user.company }) }}</span>
                     </div>
                     <li v-if="$store.state.auth.user.member_role === 0 || $store.state.auth.user.member_role === 2">
                        <router-link
                           :to="{ name: 'dashboard' }"
                           :title="$t('message.dashboard')"
                           tabindex="0"
                        >
                           <span>{{ $t('message.dashboard') }}</span>
                        </router-link>
                     </li>
                     <li v-if="$store.state.auth.user.member_role === 2">
                        <router-link
                           :to="{ name: 'admin-dashboard' }"
                           :title="$t('pagetitle.admin.dashboard')"
                           tabindex="0"
                        >
                           <span>{{ $t('message.admin') }}</span>
                        </router-link>
                     </li>
                     <li>
                        <a
                           :title="$t('message.logout')"
                           tabindex="0"
                           @click.prevent="LogoutThisAccount"
                           @keyup.enter="LogoutThisAccount"
                        >
                           <span>{{ $t('message.logout') }}</span>
                        </a>
                     </li>
                  </ul>
                  <ul v-else>
                     <li>
                        <router-link
                           :to="{ name: 'login' }"
                           :title="$t('message.login')"
                           tabindex="0"
                        >
                           <span>{{ $t('message.login') }}</span>
                        </router-link>
                     </li>
                     <li>
                        <router-link
                           :to="{ name: 'register' }"
                           :title="$t('message.register')"
                           tabindex="0"
                        >
                           <span>{{ $t('message.register') }}</span>
                        </router-link>
                     </li>
                  </ul>
               </div>
               <div id="topbar-user-preferences">
                  <lang-switcher/>
                  <theme-switcher/>
               </div>
            </div>
         </div>
      </nav>
      <div
         id="main-header"
         ref="mainNav"
      >
         <div class="container header-std-layout">
            <div id="logo-wrapper">
               <logo :invert-color="currentTheme === 'dark' || (isTransparent && isTopOfPage)"/>
            </div>
            <div id="navigation-wrapper">
               <a
                  id="mobile-navigation-toggle"
                  ref="mobileNavigation"
                  @click.prevent="ToogleMobileNavigation"
                  @keyup.enter="ToogleMobileNavigation"
               >
                  <icon :icon="['fal', 'compass']"/>
                  <b>{{ $t('header.showMobileMenu') }}</b>
               </a>
               <nav
                  id="primary-navigation"
                  v-closable="{ exclude: ['mobileNavigation', 'submenuToogle1', 'submenuToogle2'], handler: 'ClearMobileNavigation' }"
                  :class="{ 'hide-mobile' : !showMobileNav }"
               >
                  <ul>
                     <li
                        @click="ClearMobileNavigation"
                        @keyup.enter="ClearMobileNavigation"
                     >
                        <router-link
                           :title="$t('header.menuHome')"
                           :to="{ name: 'home' }"
                           tabindex="0"
                        >
                           <icon :icon="['fal', 'home']"/>
                           <b>{{ $t('header.menuHome') }}</b>
                        </router-link>
                     </li>
                     <li
                        @click="ClearMobileNavigation"
                        @keyup.enter="ClearMobileNavigation"
                     >
                        <router-link
                           :title="$t('header.menuPackages')"
                           :to="{ name: 'packages' }"
                           tabindex="0"
                        >
                           <icon :icon="['fal', 'cubes']"/>
                           <b>{{ $t('header.menuPackages') }}</b>
                        </router-link>
                     </li>
                     <li>
                        <a
                           ref="submenuToogle2"
                           :aria-expanded="about.isOpened"
                           class="submenu-button"
                           role="button"
                           tabindex="0"
                           @click.prevent="ToogleSubMenuAbout"
                           @keyup.enter="ToogleSubMenuAbout"
                        >
                           <icon :icon="['fal', 'user']"/>
                           <b>
                              <span>{{ $t('header.menuAbout') }}</span>
                              <div :class="['arrow', { 'opened': about.isOpened }]">
                                 <span/>
                              </div>
                           </b>
                        </a>
                        <ul
                           v-show="about.isOpened"
                           v-closable="{ exclude: ['submenuToogle2'], handler: 'CloseAboutSubMenu' }"
                           class="header-submenu"
                           tabindex="-1"
                        >
                           <li
                              @click="[ClearMobileNavigation() && CloseAboutSubMenu()]"
                              @keyup.enter="[ClearMobileNavigation() && CloseAboutSubMenu()]"
                           >
                              <router-link :to="{ name: 'about' }">
                                 <span>{{ aboutDropdown1 }}</span>
                              </router-link>
                           </li>
                           <li
                              @click="[ClearMobileNavigation() && CloseAboutSubMenu()]"
                              @keyup.enter="[ClearMobileNavigation() && CloseAboutSubMenu()]"
                           >
                              <router-link to="/about-us#video">
                                 <span>{{ aboutDropdown2 }}</span>
                              </router-link>
                           </li>
                           <li
                              @click="[ClearMobileNavigation() && CloseAboutSubMenu()]"
                              @keyup.enter="[ClearMobileNavigation() && CloseAboutSubMenu()]"
                           >
                              <router-link to="/about-us#team">
                                 <span>{{ aboutDropdown3 }}</span>
                              </router-link>
                           </li>
                           <li
                              @click="[ClearMobileNavigation(), CloseAboutSubMenu()]"
                              @keyup.enter="[ClearMobileNavigation(), CloseAboutSubMenu()]"
                           >
                              <router-link :to="{ name: 'testimonials' }">
                                 <span>{{ $t('pages.sidebar.testimonialPage') }}</span>
                              </router-link>
                           </li>
                        </ul>
                     </li>
                     <li v-if="faq.isInitiated === true">
                        <a
                           ref="submenuToogle"
                           :aria-expanded="faq.isOpened"
                           class="submenu-button"
                           role="button"
                           tabindex="0"
                           @click.prevent="ToogleSubMenu"
                           @keyup.enter="ToogleSubMenu"
                        >
                           <icon :icon="['fal', 'info']"/>
                           <b>
                              <span>{{ $t('header.menuFAQ') }}</span>
                              <div :class="['arrow', { 'opened': faq.isOpened }]">
                                 <span/>
                              </div>
                           </b>
                        </a>
                        <ul
                           v-show="faq.isOpened"
                           v-closable="{ exclude: ['submenuToogle'], handler: 'CloseFAQSubMenu' }"
                           class="header-submenu"
                           tabindex="-1"
                        >
                           <li
                              v-for="(sublink, subindex) in faq.data"
                              :key="subindex"
                              @click="[CloseFAQSubMenu(), ClearMobileNavigation()]"
                              @keyup.enter="[CloseFAQSubMenu(), ClearMobileNavigation()]"
                           >
                              <router-link
                                 v-if="!sublink.title"
                                 :to="{ name: sublink.route, params: { id: sublink.id, slug: DynTranslate('slug', sublink.translation) } }"
                                 :title="DynTranslate('title', sublink.translation)"
                              >
                                 <span>{{ DynTranslate('title', sublink.translation) }}</span>
                              </router-link>
                              <router-link
                                 v-else
                                 :to="{ name: sublink.route }"
                                 :title="$t(sublink.title)"
                              >
                                 <span>{{ $t(sublink.title) }}</span>
                              </router-link>
                           </li>
                        </ul>
                     </li>
                     <li id="true-market-value-menu-item">
                        <a
                           href="https://truemarketvalue.dk/"
                           tabindex="0"
                           :title="$t('header.menuTrueMarketValue')"
                           target="_blank"
                           rel="noreferrer noopener"
                           @click="ClearMobileNavigation"
                           @keyup.enter="ClearMobileNavigation"
                        >
                           <icon :icon="['fal', 'building']"/>
                           <b>{{ $t('header.menuTrueMarketValue') }}</b>
                        </a>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>
      </div>
   </header>
</template>

<script>
   import LangSwitcher from '../elements/LangSwitcher'
   import ThemeSwitcher from '../elements/ThemeSwitcher'
   import Logo from '../elements/PropLogo'
   import { UpdateAlert, AddClassToElement, IsEmpty, DynTranslate, Translate } from '../../functions'
   import { mapState } from 'vuex'
   import { mapGetters } from 'vuex'

   export default {
      components: {
         LangSwitcher,
         ThemeSwitcher,
         Logo
      },
      data() {
         return {
            topNav: {
               unlogged: [
                  {
                     name: 'message.register',
                     path: 'register'
                  },
                  {
                     name: 'message.login',
                     path: 'login'
                  }
               ],
               user: [
                  {
                     name: 'message.dashboard',
                     path: 'dashboard'
                  },
                  {
                     name: 'message.logout'
                  }
               ],
               admin: [
                  {
                     name: 'message.dashboard',
                     path: 'admin.dashboard'
                  }
               ]
            },
            isHeaderCollapsed: false,
            headerStates: [
               'collapsing',
               'collapsed',
               'expanding'
            ],
            mobileNavStates: [
               'opening',
               'opened',
               'closing'
            ],
            headerAnimationTime: 250,
            lastTopOffset: 0,
            offsetBufferCounter: 0,
            showMobileNav: false,
            faq: {
               isOpened: false,
               data: [],
               isInitiated: false
            },
            about: {
               isOpened: false
            },
            topOfPage: true
         }
      },
      computed: {
         ...mapState('navigation', ['isTransparent']),
         ...mapState('theme', ['currentTheme']),
         ...mapState('storage', ['faqs']),
         isTopOfPage() {
            return this.topOfPage
         },
         ...mapGetters('settings', [
            'translations'
         ]),
         aboutDropdown1() {
            return Translate('aboutDropdown1', this.translations, this.$i18n.locale)
         },
         aboutDropdown2() {
            return Translate('aboutDropdown2', this.translations, this.$i18n.locale)
         },
         aboutDropdown3() {
            return Translate('aboutDropdown3', this.translations, this.$i18n.locale)
         }
      },
      watch: {
         faqs(value) {
            this.RenderFAQMenuItem(value)
         }
      },
      created() {
         this.DynTranslate = DynTranslate
         this.$store.dispatch('storage/fetch_faqs')
      },
      mounted() {
         if (document.addEventListener) {
            window.addEventListener('scroll', this.HeaderTransform, false)
         } else {
            document.attachEvent('onscroll', this.HeaderTransform)
         }
      },
      methods: {
         LogoutThisAccount() {
            this.$store.dispatch('auth/logout').then(() => {
               UpdateAlert('success.auth.loggedOut', 'success')
               this.$router.push({ name: 'home' })
            }).catch(() => {
               UpdateAlert('error.auth.cantLogOut', 'warning')
            })
         },
         ToogleMobileNavigation() {
            this.showMobileNav = !this.showMobileNav
         },
         ClearMobileNavigation() {
            this.showMobileNav = false
         },
         /*ToogleMobileNavigation() {
            this.showMobileNav = true
            AddClassToElement(this.mobileNavStates, this.$refs.mainNav, 0)
            setTimeout(()=> {
               AddClassToElement(this.mobileNavStates, this.$refs.mainNav, 1)
            }, this.headerAnimationTime)
         },
         ClearMobileNavigation() {
            AddClassToElement(this.mobileNavStates, this.$refs.mainNav, 2)
            setTimeout(()=> {
               this.showMobileNav = false
               AddClassToElement(this.mobileNavStates, this.$refs.mainNav)
            }, this.headerAnimationTime)
         },*/
         ChangeHeaderState(state) {
            const states = this.headerStates
            const header = this.$refs.header

            states.map((val, i) => {
               if (state === i) {
                  header.classList.add(val)
               } else {
                  header.classList.remove(val)
               }
            })
         },
         CollapseHeader() {
            AddClassToElement(this.headerStates, this.$refs.header, 0)
            setTimeout(()=> {
               AddClassToElement(this.headerStates, this.$refs.header, 1)
            }, this.headerAnimationTime)
            this.isHeaderCollapsed = true
         },
         ExpandHeader() {
            if (this.isHeaderCollapsed !== false) {
               AddClassToElement(this.headerStates, this.$refs.header, 2)
               setTimeout(()=> {
                  AddClassToElement(this.headerStates, this.$refs.header)
               }, this.headerAnimationTime)
            }
            this.isHeaderCollapsed = false
         },
         HeaderTransform() {
            const mainHeader = this.$refs.header
            const topOffset = window.pageYOffset || document.documentElement.scrollTop

            if (topOffset >= 5) {
               if (mainHeader.classList.contains('top-of-page')) {
                  mainHeader.classList.remove('top-of-page')
                  this.topOfPage = false
               }
            } else {
               if (!mainHeader.classList.contains('top-of-page')) {
                  mainHeader.classList.add('top-of-page')
                  this.topOfPage = true
               }
            }

            if (topOffset < 5) {
               this.ExpandHeader()
            } else if (this.lastTopOffset > topOffset) {
               if (this.offsetBufferCounter === 3) {
                  this.ExpandHeader()
               }
               this.offsetBufferCounter = this.offsetBufferCounter + 1
            } else if (this.isHeaderCollapsed === false) {
               this.CollapseHeader()
               this.offsetBufferCounter = 0
            }
            this.lastTopOffset = topOffset
         },
         RenderFAQMenuItem(payload) {
            if (!IsEmpty(payload) && this.faq.isInitiated === false) {
               this.faq.data = payload
               this.faq.isInitiated = true
            }
         },
         ToogleSubMenu() {
            this.faq.isOpened = !this.faq.isOpened
         },
         ToogleSubMenuAbout() {
            this.about.isOpened = !this.about.isOpened
         },
         CloseFAQSubMenu() {
            this.faq.isOpened = false
         },
         CloseAboutSubMenu() {
            this.about.isOpened = false
         }
      }
   }
</script>

<style lang="scss" scoped>
   @import '~@/variables';
   @import '~@/functions';

   $mobile-logo-width: calc(#{$logo-collapsed-width} + #{$small-gap} + #{$small-gap});

   @media screen and (max-width: $breakpoint-laptop) {
      #true-market-value-menu-item {
         display: none;
      }
   }

   header {
      position: fixed;
      z-index: 100000;
      top: 0;
      left: 0;
      right: 0;
      @include smooth-ease();

      &.collapsed, &.collapsing {
         top: -$header-topbar-height;

         #main-header {
            > div {
               grid-template-columns: $logo-collapsed-width 1fr;

               #logo-wrapper {
                  a {
                     top: 0;

                     .prop-logo {
                        height: $logo-collapsed-height;
                        width: $logo-collapsed-width;
                     }
                  }
               }
            }
         }
      }

      &.expanding {
         top: 0;
      }

      .header-std-layout {
         display: grid;
         grid-template-columns: $logo-width 1fr;
         grid-column-gap: $medium-gap;
         @include transition(grid-template-columns .25s ease-in-out, width .25s ease-in-out .125s);

         @media screen and (max-width: $breakpoint-small-mobile) {
            grid-template-columns: 1fr;
         }
      }

      #topbar-navigation {
         background: var(--background-accent-color-alt);
         height: $header-topbar-height;
         border-bottom: 1px solid rgba(0, 0, 0, 0.1);

         #topbar-navigation-links {
            float: left;

            #topbar-auth-user {
               display: inline-block;
               padding: $small-gap 12px;
               font-size: $smaller-font-size;
               font-style: italic;

               @media screen and (max-width: $breakpoint-pablet) {
                  display: none;
               }
            }

            ul {
               font-size: 0;

               li {
                  @include smooth-ease();

                  &:hover {
                     background: var(--header-hover-color);
                  }

                  a {
                     font-size: $smaller-font-size;
                     padding: 10px 12px;
                     line-height: calc(#{$header-topbar-height} - 1px);

                     @media screen and (max-width: $breakpoint-small-mobile) {
                        padding: $small-gap;
                     }
                  }
               }
            }

            a:focus {
               @include input-outline();
            }
         }

         #topbar-user-preferences {
            float: right;
            font-size: 0;

            &> div {
               float: left;
            }

            button {
               font-size: $smaller-font-size;
               text-decoration: underline;
               color: var(--link-color);

               &:hover {
                  background: var(--header-hover-color);
               }
            }
         }
      }

      #main-header {
         background: var(--background-accent-color);
         box-shadow: var(--header-box-shadow);
         height: $header-main-height;
         padding: $small-gap 0;

         .header-std-layout {
            @media screen and (max-width: $breakpoint-small-mobile) {
               grid-template-columns: $mobile-logo-width 1fr;
               grid-column-gap: $small-gap;
            }
         }

         > div {
            height: $header-main-inner-height;

            #logo-wrapper {
               a {
                  position: absolute;
                  top: -$header-topbar-height;
                  @include smooth-ease();

                  @media screen and (max-width: $breakpoint-small-mobile) {
                     position: initial;
                     top: unset;
                  }
               }
            }

            #navigation-wrapper {
               @include smooth-ease();

               #mobile-navigation-scene {
                  .mobile.overlay {
                     position: fixed;
                     @include transition(opacity .25s ease-in-out);
                  }

                  .mobile.close {
                     z-index: 100;
                     position: fixed;
                     color: $white-color;
                     top: $medium-gap;
                     right: $medium-gap;

                     svg {
                        font-size: 32px;
                        width: 32px;
                     }
                  }
               }

               #mobile-navigation-toggle {
                  display: none;
                  grid-template-columns: 25px 1fr;
                  grid-column-gap: $micro-gap;
                  padding: $small-gap;
                  color: var(--font-color);
                  @include rounded($small-gap);
                  @include smooth-ease();
                  margin: $small-gap 0;
                  text-decoration: underline;

                  &:hover {
                     background: var(--background-accent-color-alt);
                  }

                  i.fa-icon {
                     font-size: 22px;
                  }

                  b {
                     display: inline-block;
                  }

                  @media screen and (max-width: $breakpoint-pablet) {
                     display: grid;
                  }
               }

               #primary-navigation {
                  @media screen and (max-width: $breakpoint-pablet) {
                     display: block;
                     background: var(--background-accent-color-alt);
                     width: 100%;
                     box-shadow: 2px 2px 0 #d9d9d9;
                     border-radius: 4px;

                     &:before {
                        width: 0;
                        height: 0;
                        border: 5px solid transparent;
                        border-color: var(--input-background-color-alt) transparent transparent transparent;
                        transform: rotate(180deg);
                        margin-top: -10px;
                        margin-left: 8px;
                     }
                  }

                  @media screen and (max-width: $breakpoint-small-mobile) {
                     position: fixed;
                     bottom: 0;
                     left: 0;
                     right: 0;
                     border-radius: 0;
                     box-shadow: unset;

                     &>ul li .header-submenu {
                        max-width: unset;
                        min-width: unset;
                     }
                  }

                  &.hide-mobile {
                     @media screen and (max-width: $breakpoint-pablet) {
                        display: none;
                     }
                  }

                  &>ul {
                     li {
                        padding: $small-gap 0;
                        margin-right: $medium-gap;

                        @media screen and (max-width: $breakpoint-pablet) {
                           margin: 0;
                           display: block;
                           padding: $small-gap;
                        }

                        .header-submenu {
                           position: absolute;
                           background: var(--background-accent-color-alt);
                           max-width: 230px;
                           min-width: 160px;
                           box-shadow: 2px 2px 0 #d9d9d9;
                           @include rounded($main-button-rounding);
                           margin-top: $small-gap;

                           @media screen and (max-width: $breakpoint-pablet) {
                              position: initial;
                              box-shadow: unset;
                              margin: 0;
                              min-width: initial;
                              @include rounded(initial);
                           }

                           &:before {
                              width: 0;
                              height: 0;
                              border: 5px solid transparent;
                              border-color: var(--input-background-color-alt) transparent transparent transparent;
                              transform: rotate(180deg);
                              margin-top: -10px;
                              margin-left: 8px;

                              @media screen and (max-width: $breakpoint-pablet) {
                                 display: none;
                              }
                           }

                           &>li {
                              padding: 8px;
                              width: 100%;
                              display: block;

                              a {
                                 grid-template-columns: 1fr;
                                 color: var(--link-color);
                                 @include rounded(unset);

                                 &:hover {
                                    background: rgba(0, 0, 0, 0.05);
                                 }

                                 &:first-of-type {
                                    @include rounded($small-gap, $small-gap, null, null);
                                 }

                                 &:last-of-type {
                                    @include rounded(null, null, $small-gap, $small-gap);
                                 }

                                 span {
                                    @include ellipsis();
                                 }
                              }
                           }
                        }

                        &>a {
                           display: grid;
                           grid-template-columns: 25px 1fr;
                           grid-column-gap: $micro-gap;
                           padding: $small-gap;
                           color: var(--font-color);
                           @include rounded($small-gap);
                           @include smooth-ease();

                           &[rel="noreferrer noopener"]:before {
                              color: var(--header-font-color);
                              right: 1px;
                              top: 1px;
                              font-size: 10px;
                           }

                           &:hover, &:focus {
                              background: var(--background-accent-color-alt);
                           }

                           &:focus {
                              @include input-outline();
                           }

                           i {
                              font-size: 22px;
                           }

                           b {
                              display: inline-block;
                           }
                        }

                        &:last-of-type {
                           margin: 0;
                        }
                     }
                  }
               }
            }
         }
      }
   }
</style>