import { render, staticRenderFns } from "./PropLogo.vue?vue&type=template&id=7132639c&scoped=true&"
import script from "./PropLogo.vue?vue&type=script&lang=js&"
export * from "./PropLogo.vue?vue&type=script&lang=js&"
import style0 from "./PropLogo.vue?vue&type=style&index=0&id=7132639c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7132639c",
  null
  
)

export default component.exports