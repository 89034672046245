import axios from 'axios'

const auth = {
   namespaced: true,
   state: {
      ttl: '',
      status : '',
      token: '',
      user: {}
   },
   mutations: {
      set_ttl(state) {
         const minutes = 60
         state.ttl = new Date(new Date().getTime() + minutes * 60 * 1000)
      },
      set_status(state, status) {
         state.status = status
      },
      set_user(state, user) {
         state.user = user
      },
      set_token(state, token) {
         state.token = token
         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      },
      remove_token(state) {
         state.token = ''
      },
      reset_user(state) {
         state.user = {}
      },
      reset_ttl(state) {
         state.ttl = ''
      },
      logout(state) {
         state.status = ''
         state.token = ''
         state.user = {}
         state.ttl = new Date()
      }
   },
   actions: {
      async login({ commit }, { email, password }) {
         return new Promise((resolve, reject) => {
            commit('set_status', 'loading')
            axios
               .post('auth/login', {
                  email: email,
                  password: password
               })
               .then((response) => {
                  commit('set_token', response.data.access_token)
                  commit('set_ttl')

                  axios.get('auth/user').then((response) => {
                     commit('set_user', response.data)
                     commit('set_status', 'success')
                     resolve(response)
                  })
               })
               .catch((error) => {
                  commit('set_status', 'error')
                  commit('remove_token')
                  commit('reset_ttl')
                  reject(error)
               })
         })
      },
      logout({ commit }) {
         return new Promise(resolve => {
            //axios.post('auth/logout').then(() => {
            commit('set_status', 'logout')
            commit('remove_token')
            commit('reset_user')
            commit('reset_ttl')
            delete axios.defaults.headers.common['Authorization']
            resolve()
            //})
         })
      },
      fetchUser({ commit, getters }) {
         return new Promise((resolve, reject) => {
            if (getters.check) {
               axios.get('auth/user').then((response) => {
                  commit('set_user', response.data)
                  resolve(response)
               }).catch((error) => {
                  reject(error)
               })
            }
         })
      },
      refreshToken({ commit, getters, dispatch }) {
         if (getters.check) {
            axios.get(('auth/refresh')).then((response) => {
               if (response.status === 401) {
                  dispatch('logout')
               } else if (response.status === 200) {
                  commit('set_token', response.data.access_token)
                  commit('set_ttl')
               }
            })
         }
      }
   },
   getters: {
      check: state => !!state.token,
      user: state => state.user,
      isAdmin: state => state.user.member_role === 2,
      isExpired: state => (new Date(state.ttl) < new Date(new Date().getTime() - (10 * 60 * 1000))),
      isDead: state => (new Date(state.ttl) < new Date())
   }
}

export default auth