import { IsArray } from '../../functions'

const alert = {
   namespaced: true,
   state: () => ({
      message: '',
      type: 'default',
      status: 'NotActive',
      timer: null
   }),
   mutations: {
      set_alert_message(state, { msg, params }) {
         if (params) {
            let messageWithParams = []
            messageWithParams.push(msg)
            if (IsArray(params)) {
               params.push(params)
            } else {
               messageWithParams.push([params])
            }
            state.message = messageWithParams
         } else {
            state.message = msg
         }
      },
      set_alert_type(state, type) {
         state.type = type
      },
      set_alert_status(state, status) {
         state.status = status
      },
      set_alert_timer(state, timer) {
         state.timer = timer
      },
      clear_alert_timer(state) {
         clearTimeout(state.timer)
      }
   },
   actions: {
      update_alert({ commit, state }, { msg, status, params }) {
         if (params) {
            commit('set_alert_message', { msg, params })
         } else {
            commit('set_alert_message', { msg })
         }
         commit('set_alert_type', status)

         if (state.status !== 'NotActive') {
            commit('set_alert_status', 'KeepActive')
            commit('clear_alert_timer')
            commit('set_alert_timer',
               window.setTimeout(() => {
                  commit('set_alert_status', 'NotActive')
               }, 2500)
            )
         } else {
            commit('set_alert_status', 'StartActive')
            commit('set_alert_timer',
               window.setTimeout(() => {
                  commit('set_alert_status', 'NotActive')
               }, 2500)
            )
         }
      }
   }
}

export default alert