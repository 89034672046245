import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from './../store'
import { loadLanguageAsync, i18n } from '../i18n'

Vue.use(VueRouter)

const router = new VueRouter({
   mode: 'history',
   scrollBehavior: function(to) {
      if (to.hash) {
         return new Promise(resolve => {
            setTimeout(() => {
               resolve({ selector: to.hash, behavior: 'smooth' })
            }, 500)
         })
         // return { selector: to.hash, behavior: 'smooth' }
      } else {
         return { x: 0, y: 0 }
      }
   },
   routes
})

router.beforeEach((to, from, next) => {
   loadLanguageAsync(i18n.locale).then(() => {
      if (to.matched.some(record => typeof record.meta.auth === 'boolean')) {
         if (to.meta.auth === true) {
            if (store.getters['auth/check'] === false) {
               next({
                  path: '/login'
               })
            }
         }

         if (to.meta.auth === false) {
            if (store.getters['auth/check'] === true) {
               next({
                  path: '/'
               })
            }
         }
      }

      if (to.meta.auth && to.meta.auth.roles) {
         if (to.matched.some(record => record.meta.auth.roles)) {
            if (store.getters['auth/check'] === true) {
               if (!store.getters['auth/isAdmin']) {
                  next({
                     path: '/403'
                  })
               }
            } else {
               next({
                  path: '/login'
               })
            }
         }
      }
      next()
   })
})

export default router