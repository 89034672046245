import { AjaxGet, MakeTranslatable } from '../../functions'

const settings = {
   namespaced: true,
   state: () => ({
      settings: {}
   }),
   mutations: {
      set_settings(state, data) {
         state.settings = data
      }
   },
   actions: {
      fetch_settings({ commit }) {
         AjaxGet('global/settings', (resp) => {
            commit('set_settings', resp.data)
         })
      }
   },
   getters: {
      contactEmail: state => state.settings.contactEmail,
      contactPhone: state => state.settings.contactPhone,
      facebook: state => state.settings.facebook,
      latitude: state => state.settings.latitude,
      longitude: state => state.settings.longitude,
      postal: state => state.settings.postal,
      featuredImage: state => state.settings.featuredImage,
      vat: state => state.settings.vat,
      landingFeaturedMedia: state => state.settings.landingFeaturedMedia,
      translations: state => ({ translations: MakeTranslatable(state.settings.translations) })
   }
}

export default settings