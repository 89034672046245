const theme = {
   namespaced: true,
   state: () => ({
      currentTheme: 'light'
   }),
   mutations: {
      set_current_theme(state, payload) {
         state.currentTheme = payload
      }
   },
   actions: {
      change_current_theme({ commit }, payload) {
         commit('set_current_theme', payload)
      }
   }
}

export default theme