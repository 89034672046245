<template>
   <router-link
      :to="{ name: 'home' }"
      tabindex="-1"
      v-bind="$attrs"
   >
      <div
         ref="logo"
         :class="[
            'prop-logo',
            { 'clean' : cleanLogo },
            { 'on-dark' : invertColor }
         ]"
      />
   </router-link>
</template>

<script>
   import MainLogo from '../../../img/logo/prop-exchange-logo.svg'
   import MainLogoWhite from '../../../img/logo/prop-exchange-logo-white.svg'
   import CleanLogo from '../../../img/logo/clean_logo.png'
   import CleanLogoWhite from '../../../img/logo/clean_logo_white.png'

   export default {
      props: {
         cleanLogo: {
            type: Boolean,
            default: false,
            required: false
         },
         invertColor: {
            type: Boolean,
            default: false,
            required: false
         }
      },
      watch: {
         'invertColor': function() {
            this.UpdateLogo()
         },
         'cleanLogo': function() {
            this.UpdateLogo()
         }
      },
      mounted() {
         this.UpdateLogo()
      },
      methods: {
         UpdateLogo() {
            this.$refs.logo.style.backgroundImage = `url(${this.LogoSrc()})`
         },
         LogoSrc() {
            let src = MainLogo

            if (this.invertColor) {
               src = MainLogoWhite
            }

            /*if (this.cleanLogo) {
               if (this.invertColor) {
                  src = CleanLogoWhite
               }
            } else {
               if (this.invertColor) {
                  src = MainLogoWhite
               } else {
                  src = MainLogo
               }
            }*/
            return src
         }
      }
   }
</script>

<style lang="scss" scoped>
   @import '~@/variables';
   @import '~@/functions';

   .prop-logo {
      width: $logo-width;
      height: $logo-height;
      background-size: cover !important;
      margin: auto;
      @include smooth-ease();
   }

   #footer-logo {
      .prop-logo {
         opacity: .25;
      }
   }

   main {
      &.transparent-header {
         header.top-of-page {
            .prop-logo {
               background-size: contain;
            }
         }
      }

      header {
         &.collapsing, &.collapsed {
            .prop-logo {
               height: $logo-collapsed-height;
               width: $logo-collapsed-width;
            }
         }

         @media screen and (max-width: $breakpoint-small-mobile) {
            .prop-logo {
               height: $logo-collapsed-height;
               width: $logo-collapsed-width;
            }
         }
      }
   }
</style>