<template>
   <div id="theme-changer">
      <button
         tabindex="0"
         @click.prevent="SwitchTheme"
      >
         <icon :icon="['fad', currentTheme === 'dark' ? 'sun-cloud' : 'moon']"/>
      </button>
   </div>
</template>

<script>
   import { mapState } from 'vuex'

   export default {
      computed: {
         ...mapState('theme', ['currentTheme'])
      },
      methods: {
         SwitchTheme() {
            if (this.currentTheme === 'dark') {
               this.$store.dispatch('theme/change_current_theme', 'light')
            }
            else {
               this.$store.dispatch('theme/change_current_theme', 'dark')
            }
         }
      }
   }
</script>

<style lang="scss" scoped>
   @import '~@/variables';
   @import '~@/functions';

   #theme-changer {
      button {
         @include square(34px);
         padding: $small-gap;

         &:focus {
            @include input-outline();
         }

         &:hover {
            background: var(--header-hover-color);
         }
      }

      i {
         @include square(15px);
         color: var(--header-font-color);
         text-align: left;
         font-size: 16px;
      }
   }
</style>