import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import store from '../store'

Vue.use(VueI18n)

export const i18n = new VueI18n({
   locale: store.state.language.currentLanguage,
   silentTranslationWarn: true,
   fallbackLocale: 'en'
})

const loadedLanguages = []

function setI18nLanguage(lang) {
   i18n.locale = lang
   axios.defaults.headers.common['Accept-Language'] = lang
   document.querySelector('html').setAttribute('lang', lang)
}

export async function loadLanguageAsync(lang) {
   if (loadedLanguages.includes(lang)) {
      if (i18n.locale !== lang) setI18nLanguage(lang)
      return Promise.resolve()
   }

   return axios.get(`/global/translations/${lang}`).then((response) => {
      let msgs = response.data
      loadedLanguages.push(lang)
      i18n.setLocaleMessage(lang, msgs)
      setI18nLanguage(lang)
   })
}

export default i18n