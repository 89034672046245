import { AjaxGet } from '../../functions'

const storage = {
   namespaced: true,
   state: () => ({
      faqs: []
   }),
   mutations: {
      set_faqs(state, data) {
         state.faqs = data
      }
   },
   actions: {
      fetch_faqs({ commit }) {
         AjaxGet('page/faqs', (resp) => {
            commit('set_faqs', resp.data)
         })
      }
   }
}

export default storage