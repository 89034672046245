import Echo from 'laravel-echo'
import 'es6-promise/auto'
import Vue from 'vue'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueClosable from 'vue-closable'
import VueMeta from 'vue-meta'
import { VueReCaptcha } from 'vue-recaptcha-v3'

window.Pusher = require('pusher-js')

window.Echo = new Echo({ broadcaster: 'pusher', key: process.env.PUSHER_APP_KEY, cluster: 'eu'})

Vue.use(VueSmoothScroll)
Vue.use(VueClosable)
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

Vue.use(VueReCaptcha, {
   siteKey: '6LetBqYZAAAAAGTuRCHdrd-evX-94SPoWfALuz-r',
   loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
   }
})

import i18n from './i18n'
import router from './router'
import store from './store'

Vue.router = router
Vue.config.productionTip = false

// ===========================================
// Axios setup
// ===========================================
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.prototype.$http = axios
axios.defaults.baseURL = '/api/v1'
Vue.use(VueAxios, axios)

// ===========================================
// FontAwesome setup
// ===========================================
import '../lib/font-awesome/css/fontawesome.min'
import '../lib/font-awesome/css/brands.min'
import '../lib/font-awesome/css/duotone.min'
import '../lib/font-awesome/css/light.min'

import FontAwesomeIcon from './components/elements/Icon'

Vue.component('icon', FontAwesomeIcon)

// ===========================================
// Loading assets
// ===========================================
import Master from './components/Master'
import '../scss/fonts'
import '../scss/main'

Vue.component('pagination', require('laravel-vue-pagination'))

if (store.state.auth.token) {
   axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`
   Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`
   store.dispatch('auth/fetchUser')
      .then(() => {
         store.dispatch('auth/refreshToken')
      }).catch(() => {
         store.dispatch('auth/logout')
      })
}

export const eventBus = new Vue()

const app = new Vue({
   i18n,
   router,
   store,
   el: '#app',
   mounted() {
      document.dispatchEvent(new Event('render-event'))
   },
   render: h => h(Master)
})

export default app
