<template>
   <div
      v-if="initLocale"
      id="locale-changer"
   >
      <dropdown
         v-model="activeLocale"
         :data="langs"
         value-key="name"
         :small-icons="true"
         :init="initLocale"
         :is-topbar-element="true"
      />
   </div>
</template>

<script>
   import Dropdown from './Dropdown'
   import { AjaxGet } from '../../functions'

   export default {
      components: {
         Dropdown
      },
      data() {
         return {
            langs: [],
            activeLocale: this.$i18n.locale,
            initLocale: null
         }
      },
      watch: {
         activeLocale: function(newLocale) {
            this.$store.dispatch('language/change_current_language', newLocale)
         }
      },
      created() {
         this.FetchLanguages()
      },
      methods: {
         FetchLanguages() {
            AjaxGet('/global/languages', (resp) => {
               this.langs = resp.data
               this.initLocale = this.SelectInitLangObject()
            })
         },
         SelectInitLangObject() {
            const langArray = this.langs
            const currentLang = this.$i18n.locale
            let output = langArray[0]
            langArray.map((object) => {
               if (object.name === currentLang) {
                  output = object
               }
            })
            return output
         }
      }
   }
</script>

<style lang="scss" scoped>
   @import '~@/variables';
   @import '~@/functions';

   #locale-changer {
      height: 35px;
   }
</style>