import axios from 'axios'
import store from './store'

export function date2str(x, y) {
   var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
   }
   y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? '0' : '') + z[v.slice(-1)]).slice(-2)
   })

   return y.replace(/(y+)/g, function(v) {
      return x.getFullYear().toString().slice(-v.length)
   })
}

export function PrintFileExtensionFormat(extension) {
   if (extension === 'image/png') {
      return 'png, '
   } else if (extension === 'image/jpeg') {
      return 'jpeg, '
   } else if (extension === 'application/zip' || extension === 'application/x-zip-compressed' || extension === 'multipart/x-zip' || extension === 'application/octet-stream') {
      return 'zip, '
   } else if (extension === 'video/mp4') {
      return 'mp4, '
   } else if (extension === 'video/quicktime') {
      return 'mov, '
   } else if (extension === 'video/x-msvideo') {
      return 'avi, '
   } else if (extension === 'application/pdf') {
      return 'pdf, '
   }
}

export function FormatPrice(price, ext = '') {
   if (price) {
      const locale = this.$i18n.locale
      let parts = price.toString().split('.')

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, Translate('numberSeparator', store.getters['settings/translations'], locale))

      const format = ext + ' ' + parts.join('.')
      return format
   }
}

export function PrettyDate(date) {
   const day = new Date(date)
   const months = {
      0: 'Jan', 1: 'Feb', 2: 'Mar', 3: 'Apr', 4: 'May', 5: 'Jun', 6: 'Jul', 7: 'Aug', 8: 'Sep', 9: 'Oct', 10: 'Nov', 11: 'Dec'
   }
   const output = day.getDate() + ' ' + months[day.getMonth()] + ' ' + day.getFullYear()
   return output
}

export function PrettyTimestamp(timestamp) {
   return PrettyDate(timestamp.replace(/-/g, '/'))
}

export function OrderBy(list, order, column) {
   return list.sort((a, b) => {
      if (column === 'price') return order * (a[column] - b[column])
      else return order * a[column].localeCompare(b[column])
   })
}

export function SortBy(newSortKey, sortKey, sortOrders) {
   sortKey = newSortKey
   sortOrders[newSortKey] = sortOrders[newSortKey] * -1
}

export function CapitalizeString(string) {
   return string.charAt(0).toUpperCase() + string.slice(1)
}

export function LowercaseString(string) {
   return string.toLowerCase()
}

export function IsArray(arr) {
   if (Object.prototype.toString.call(arr) === '[object Array]') {
      return true
   }
   return false
}

export function IsFileObject(arr) {
   if (Object.prototype.toString.call(arr) === '[object File]') {
      return true
   }
   return false
}

export function IsEmpty(obj) {
   const hasOwnProperty = Object.prototype.hasOwnProperty

   if (obj == null) {
      return true
   }
   if (obj.length > 0) {
      return false
   }
   if (obj.length === 0) {
      return true
   }
   if (typeof obj !== 'object') {
      return true
   }

   for (let key in obj) {
      if (hasOwnProperty.call(obj, key)) {
         return false
      }
   }

   return true
}

export function AjaxGet(url, callback = null) {
   axios.get(url)
      .then((resp) => {
         if (callback) {
            callback(resp)
         }
      }).catch((err) => {
         store.dispatch('alert/update_alert', {
            'msg': err,
            'status': 'warning'
         })
      })
}

export function AjaxDelete(url, callback = null) {
   axios.delete(url)
      .then((resp) => {
         store.dispatch('alert/update_alert', resp.data)
         if (callback) {
            callback(resp)
         }
      })
      .catch((err) => {
         store.dispatch('alert/update_alert', {
            'msg': err,
            'status': 'warning'
         })
      })
}

export function AjaxPost(url, query, successCB = null, failedCB = null, config = {}) {
   axios.post(url, query, config)
      .then((resp) => {
         store.dispatch('alert/update_alert', resp.data)
         if (successCB) {
            successCB(resp)
         }
      })
      .catch((err) => {
         store.dispatch('alert/update_alert', {
            'msg': err,
            'status': 'warning'
         })
         if (failedCB) {
            failedCB(err)
         }
      })
}

export function UpdateAlert(msg, status = 'default') {
   store.dispatch('alert/update_alert', {
      'msg': msg,
      'status': status
   })
}

export function GrabInitObjectFromArray(id, arr, fallback = null, key = null) {
   const arrCount = arr.length
   let output = fallback

   if (id !== null) {
      for (let i = 0; i < arrCount; i++) {
         if (arr[i].id === id) {
            if (!key) {
               output = arr[i]
            } else {
               output = arr[i][key]
            }
            continue
         }
      }
   }

   return output
}

export function GetSelectedCountry(countryID, countries) {
   const fallback = { icon: 'globe-stand', label: 'example.selectCountry' }
   return GrabInitObjectFromArray(countryID, countries, fallback)
}

export function GetSelectedProperty(propertyID, properties) {
   const fallback = { icon: 'building', label: 'example.selectProperty' }
   return GrabInitObjectFromArray(propertyID, properties, fallback)
}

export function GetSelectedMember(memberID, members) {
   const fallback = { icon: 'user', label: 'example.selectMember' }
   return GrabInitObjectFromArray(memberID, members, fallback)
}

export function GetSelectedLanguage(languageID, languages) {
   const fallback = { icon: 'language', label: 'example.selectLanguage' }
   return GrabInitObjectFromArray(languageID, languages, fallback)
}

export function GetSelectedCurrency(currencyID, currencies) {
   const fallback = { icon: 'coins', label: 'example.selectCurrency' }
   return GrabInitObjectFromArray(currencyID, currencies, fallback)
}

export function GetSelectedCategory(categoryID, categories) {
   const fallback = { icon: 'tag', label: 'example.selectCategory' }
   return GrabInitObjectFromArray(categoryID, categories, fallback)
}

export function GetSelectedAuction(auctionID, auctions) {
   const fallback = { icon: 'tag', label: 'example.selectAuction' }
   return GrabInitObjectFromArray(auctionID, auctions, fallback)
}

export function GetSelectedUserRole(authorID, authors) {
   const fallback = { icon: 'coins', label: 'example.selectRoleType' }
   return GrabInitObjectFromArray(authorID, authors, fallback)
}

export function GetSelectedAuthorType(authorID, authors) {
   const fallback = { icon: 'user-tag', label: 'example.selectAuthorType' }
   return GrabInitObjectFromArray(authorID, authors, fallback)
}

export function GetSelectedStatusType(statusID, statusTypes) {
   const fallback = { icon: 'coins', label: 'example.selectStatus' }
   return GrabInitObjectFromArray(statusID, statusTypes, fallback)
}

export function GetSelectedApprovalType(approvalStatusID, approvalTypes) {
   const fallback = { icon: 'coins', label: 'example.selectStatus' }
   return GrabInitObjectFromArray(approvalStatusID, approvalTypes, fallback)
}

export function GetSelectedBidType(bidStatusID, bidStatusTypes) {
   const fallback = { icon: 'coins', label: 'example.selectStatus' }
   return GrabInitObjectFromArray(bidStatusID, bidStatusTypes, fallback)
}

export function GetSelectedAddonType(addonTypeID, addonTypes) {
   const fallback = { icon: 'puzzle-piece', label: 'example.selectAddonType' }
   return GrabInitObjectFromArray(addonTypeID, addonTypes, fallback)
}

export function Slugify(str) {
   str = String(str).toString()
   str = str.replace(/^\s+|\s+$/g, '')
   str = str.toLowerCase()

   const swaps = {
      '0': ['°', '₀', '۰', '０'],
      '1': ['¹', '₁', '۱', '１'],
      '2': ['²', '₂', '۲', '２'],
      '3': ['³', '₃', '۳', '３'],
      '4': ['⁴', '₄', '۴', '٤', '４'],
      '5': ['⁵', '₅', '۵', '٥', '５'],
      '6': ['⁶', '₆', '۶', '٦', '６'],
      '7': ['⁷', '₇', '۷', '７'],
      '8': ['⁸', '₈', '۸', '８'],
      '9': ['⁹', '₉', '۹', '９'],
      'a': ['à', 'á', 'ả', 'ã', 'ạ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ā', 'ą', 'α', 'ά', 'ἀ', 'ἁ', 'ἂ', 'ἃ', 'ἄ', 'ἅ', 'ἆ', 'ἇ', 'ᾀ', 'ᾁ', 'ᾂ', 'ᾃ', 'ᾄ', 'ᾅ', 'ᾆ', 'ᾇ', 'ὰ', 'ά', 'ᾰ', 'ᾱ', 'ᾲ', 'ᾳ', 'ᾴ', 'ᾶ', 'ᾷ', 'а', 'أ', 'အ', 'ာ', 'ါ', 'ǻ', 'ǎ', 'ª', 'ა', 'अ', 'ا', 'ａ', 'ä'],
      'b': ['б', 'β', 'ب', 'ဗ', 'ბ', 'ｂ'],
      'c': ['ç', 'ć', 'č', 'ĉ', 'ċ', 'ｃ'],
      'd': ['ď', 'ð', 'đ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ᵭ', 'ᶁ', 'ᶑ', 'д', 'δ', 'د', 'ض', 'ဍ', 'ဒ', 'დ', 'ｄ'],
      'e': ['é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ë', 'ē', 'ę', 'ě', 'ĕ', 'ė', 'ε', 'έ', 'ἐ', 'ἑ', 'ἒ', 'ἓ', 'ἔ', 'ἕ', 'ὲ', 'έ', 'е', 'ё', 'э', 'є', 'ə', 'ဧ', 'ေ', 'ဲ', 'ე', 'ए', 'إ', 'ئ', 'ｅ'],
      'f': ['ф', 'φ', 'ف', 'ƒ', 'ფ', 'ｆ'],
      'g': ['ĝ', 'ğ', 'ġ', 'ģ', 'г', 'ґ', 'γ', 'ဂ', 'გ', 'گ', 'ｇ'],
      'h': ['ĥ', 'ħ', 'η', 'ή', 'ح', 'ه', 'ဟ', 'ှ', 'ჰ', 'ｈ'],
      'i': ['í', 'ì', 'ỉ', 'ĩ', 'ị', 'î', 'ï', 'ī', 'ĭ', 'į', 'ı', 'ι', 'ί', 'ϊ', 'ΐ', 'ἰ', 'ἱ', 'ἲ', 'ἳ', 'ἴ', 'ἵ', 'ἶ', 'ἷ', 'ὶ', 'ί', 'ῐ', 'ῑ', 'ῒ', 'ΐ', 'ῖ', 'ῗ', 'і', 'ї', 'и', 'ဣ', 'ိ', 'ီ', 'ည်', 'ǐ', 'ი', 'इ', 'ی', 'ｉ'],
      'j': ['ĵ', 'ј', 'Ј', 'ჯ', 'ج', 'ｊ'],
      'k': ['ķ', 'ĸ', 'к', 'κ', 'Ķ', 'ق', 'ك', 'က', 'კ', 'ქ', 'ک', 'ｋ'],
      'l': ['ł', 'ľ', 'ĺ', 'ļ', 'ŀ', 'л', 'λ', 'ل', 'လ', 'ლ', 'ｌ'],
      'm': ['м', 'μ', 'م', 'မ', 'მ', 'ｍ'],
      'n': ['ñ', 'ń', 'ň', 'ņ', 'ŉ', 'ŋ', 'ν', 'н', 'ن', 'န', 'ნ', 'ｎ'],
      'o': ['ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ō', 'ő', 'ŏ', 'ο', 'ὀ', 'ὁ', 'ὂ', 'ὃ', 'ὄ', 'ὅ', 'ὸ', 'ό', 'о', 'و', 'θ', 'ို', 'ǒ', 'ǿ', 'º', 'ო', 'ओ', 'ｏ', 'ö'],
      'p': ['п', 'π', 'ပ', 'პ', 'پ', 'ｐ'],
      'q': ['ყ', 'ｑ'],
      'r': ['ŕ', 'ř', 'ŗ', 'р', 'ρ', 'ر', 'რ', 'ｒ'],
      's': ['ś', 'š', 'ş', 'с', 'σ', 'ș', 'ς', 'س', 'ص', 'စ', 'ſ', 'ს', 'ｓ'],
      't': ['ť', 'ţ', 'т', 'τ', 'ț', 'ت', 'ط', 'ဋ', 'တ', 'ŧ', 'თ', 'ტ', 'ｔ'],
      'u': ['ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'û', 'ū', 'ů', 'ű', 'ŭ', 'ų', 'µ', 'у', 'ဉ', 'ု', 'ူ', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'უ', 'उ', 'ｕ', 'ў', 'ü'],
      'v': ['в', 'ვ', 'ϐ', 'ｖ'],
      'w': ['ŵ', 'ω', 'ώ', 'ဝ', 'ွ', 'ｗ'],
      'x': ['χ', 'ξ', 'ｘ'],
      'y': ['ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'ÿ', 'ŷ', 'й', 'ы', 'υ', 'ϋ', 'ύ', 'ΰ', 'ي', 'ယ', 'ｙ'],
      'z': ['ź', 'ž', 'ż', 'з', 'ζ', 'ز', 'ဇ', 'ზ', 'ｚ'],
      'aa': ['ع', 'आ', 'آ', 'å'],
      'ae': ['æ', 'ǽ'],
      'ai': ['ऐ'],
      'ch': ['ч', 'ჩ', 'ჭ', 'چ'],
      'dj': ['ђ', 'đ'],
      'dz': ['џ', 'ძ'],
      'ei': ['ऍ'],
      'gh': ['غ', 'ღ'],
      'ii': ['ई'],
      'ij': ['ĳ'],
      'kh': ['х', 'خ', 'ხ'],
      'lj': ['љ'],
      'nj': ['њ'],
      'oe': ['ø', 'ö', 'œ', 'ؤ'],
      'oi': ['ऑ'],
      'oii': ['ऒ'],
      'ps': ['ψ'],
      'sh': ['ш', 'შ', 'ش'],
      'shch': ['щ'],
      'ss': ['ß'],
      'sx': ['ŝ'],
      'th': ['þ', 'ϑ', 'ث', 'ذ', 'ظ'],
      'ts': ['ц', 'ც', 'წ'],
      'ue': ['ü'],
      'uu': ['ऊ'],
      'ya': ['я'],
      'yu': ['ю'],
      'zh': ['ж', 'ჟ', 'ژ'],
      '(c)': ['©'],
      'Aa': ['Å'],
      'A': ['Á', 'À', 'Ả', 'Ã', 'Ạ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Ā', 'Ą', 'Α', 'Ά', 'Ἀ', 'Ἁ', 'Ἂ', 'Ἃ', 'Ἄ', 'Ἅ', 'Ἆ', 'Ἇ', 'ᾈ', 'ᾉ', 'ᾊ', 'ᾋ', 'ᾌ', 'ᾍ', 'ᾎ', 'ᾏ', 'Ᾰ', 'Ᾱ', 'Ὰ', 'Ά', 'ᾼ', 'А', 'Ǻ', 'Ǎ', 'Ａ', 'Ä'],
      'B': ['Б', 'Β', 'ब', 'Ｂ'],
      'C': ['Ç', 'Ć', 'Č', 'Ĉ', 'Ċ', 'Ｃ'],
      'D': ['Ď', 'Ð', 'Đ', 'Ɖ', 'Ɗ', 'Ƌ', 'ᴅ', 'ᴆ', 'Д', 'Δ', 'Ｄ'],
      'E': ['É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ë', 'Ē', 'Ę', 'Ě', 'Ĕ', 'Ė', 'Ε', 'Έ', 'Ἐ', 'Ἑ', 'Ἒ', 'Ἓ', 'Ἔ', 'Ἕ', 'Έ', 'Ὲ', 'Е', 'Ё', 'Э', 'Є', 'Ə', 'Ｅ'],
      'F': ['Ф', 'Φ', 'Ｆ'],
      'G': ['Ğ', 'Ġ', 'Ģ', 'Г', 'Ґ', 'Γ', 'Ｇ'],
      'H': ['Η', 'Ή', 'Ħ', 'Ｈ'],
      'I': ['Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Î', 'Ï', 'Ī', 'Ĭ', 'Į', 'İ', 'Ι', 'Ί', 'Ϊ', 'Ἰ', 'Ἱ', 'Ἳ', 'Ἴ', 'Ἵ', 'Ἶ', 'Ἷ', 'Ῐ', 'Ῑ', 'Ὶ', 'Ί', 'И', 'І', 'Ї', 'Ǐ', 'ϒ', 'Ｉ'],
      'J': ['Ｊ'],
      'K': ['К', 'Κ', 'Ｋ'],
      'L': ['Ĺ', 'Ł', 'Л', 'Λ', 'Ļ', 'Ľ', 'Ŀ', 'ल', 'Ｌ'],
      'M': ['М', 'Μ', 'Ｍ'],
      'N': ['Ń', 'Ñ', 'Ň', 'Ņ', 'Ŋ', 'Н', 'Ν', 'Ｎ'],
      'O': ['Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ō', 'Ő', 'Ŏ', 'Ο', 'Ό', 'Ὀ', 'Ὁ', 'Ὂ', 'Ὃ', 'Ὄ', 'Ὅ', 'Ὸ', 'Ό', 'О', 'Θ', 'Ө', 'Ǒ', 'Ǿ', 'Ｏ', 'Ö'],
      'P': ['П', 'Π', 'Ｐ'],
      'Q': ['Ｑ'],
      'R': ['Ř', 'Ŕ', 'Р', 'Ρ', 'Ŗ', 'Ｒ'],
      'S': ['Ş', 'Ŝ', 'Ș', 'Š', 'Ś', 'С', 'Σ', 'Ｓ'],
      'T': ['Ť', 'Ţ', 'Ŧ', 'Ț', 'Т', 'Τ', 'Ｔ'],
      'U': ['Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Û', 'Ū', 'Ů', 'Ű', 'Ŭ', 'Ų', 'У', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ｕ', 'Ў', 'Ü'],
      'V': ['В', 'Ｖ'],
      'W': ['Ω', 'Ώ', 'Ŵ', 'Ｗ'],
      'X': ['Χ', 'Ξ', 'Ｘ'],
      'Y': ['Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ', 'Ÿ', 'Ῠ', 'Ῡ', 'Ὺ', 'Ύ', 'Ы', 'Й', 'Υ', 'Ϋ', 'Ŷ', 'Ｙ'],
      'Z': ['Ź', 'Ž', 'Ż', 'З', 'Ζ', 'Ｚ'],
      'AE': ['Æ', 'Ǽ'],
      'Ch': ['Ч'],
      'Dj': ['Ђ'],
      'Dz': ['Џ'],
      'Gx': ['Ĝ'],
      'Hx': ['Ĥ'],
      'Ij': ['Ĳ'],
      'Jx': ['Ĵ'],
      'Kh': ['Х'],
      'Lj': ['Љ'],
      'Nj': ['Њ'],
      'Oe': ['Œ', 'Ø'],
      'Ps': ['Ψ'],
      'Sh': ['Ш'],
      'Shch': ['Щ'],
      'Ss': ['ẞ'],
      'Th': ['Þ'],
      'Ts': ['Ц'],
      'Ya': ['Я'],
      'Yu': ['Ю'],
      'Zh': ['Ж']
   }

   Object.keys(swaps).forEach((swap) => {
      swaps[swap].forEach((s) => {
         str = str.replace(new RegExp(s, 'g'), swap)
      })
   })

   return str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
}

export function SetCookie(key, value) {
   this.$cookie.set(key, value)
}

export function GetCookie(key, fallback = null) {
   return this.$cookie.get(key) || fallback
}

export function UserUpdate() {
   store.dispatch('auth/update_user')
}

export function ConvertBooleanToText(bool) {
   return this.$i18n.t(bool ? 'message.yes' : 'message.no')
}

export function AddClassToElement(arr, el, index = null) {
   arr.map((val, i) => {
      if (index === i) {
         el.classList.add(val)
      } else {
         el.classList.remove(val)
      }
   })
}

export function Translate(key, payload, locale) {
   let outline = payload[key] || null
   let translation

   if (payload.translations) {
      translation = payload.translations.filter(obj => obj.locale === locale)
   }

   if (translation) {
      if (translation[0]) {
         outline = translation[0][key]
      }
   }

   return outline
}

export function CheckTranslation(payload) {
   return !!payload.translations
}

export function DynTranslate(key, payload, id = null) {
   const locale = this.$i18n.locale
   let outline = payload[key] || null
   let translation
   let array

   if (id !== null) {
      array = payload.filter(obj => obj.id === id)
      outline = array[0][key]
      translation = array[0].translations.filter(obj => obj.locale === locale)
   } else if (!payload.translations) {
      outline = payload[0][key] || null
      translation = payload.filter(obj => obj.locale === locale)
   } else {
      if (payload.translations) {
         translation = payload.translations.filter(obj => obj.locale === locale)
      }
   }

   if (translation) {
      if (translation[0]) {
         outline = translation[0][key]
      }
   }

   return outline
}

export function readonly() {
   return this.$route.params.type === 'view'
}

export function Addition(x, y) {
   return x + y
}

export function PropertyStatus(property) {
   const startDate = new Date(property.date)
   const nowDate = new Date()
   let currentStatus = 1

   if (property.status === 3) {
      currentStatus = 2
   } else if (startDate < nowDate) {
      currentStatus = 0
   }

   return currentStatus
}

export function CreateAddonDescriptionTranslation(descriptions) {
   const output = []
   const locales = {}
   let lastIndex = 0
   let currentLocale = null

   descriptions.forEach((bundle) => {
      currentLocale = bundle.locale

      let hasAssignedLocaleId = false
      if (Object.keys(locales).length !== 0) {
         for (let key in locales) {
            if (currentLocale === key) {
               hasAssignedLocaleId = true
            }
         }
      }

      if (!hasAssignedLocaleId) {
         locales[currentLocale] = lastIndex
         if (!output[lastIndex]) {
            output[lastIndex] = {
               addon_id: bundle.addon_id,
               locale: currentLocale
            }
         }
         lastIndex++
      }

      output[locales[bundle.locale]][`bundle_${bundle.bundle_id}`] = bundle.description
   })

   return output
}

export function GetObjectByID(arr, id, key = 'id') {
   for (let i = 0; i < arr.length; i++) {
      if (arr[i][key] === id) {
         return arr[i]
      }
   }
}

export function MakeTranslatable(translations) {
   const output = []
   const locales = {}
   let lastIndex = 0
   let currentLocale = null

   if (translations && translations.length > 0) {
      for (let i = 0; i < translations.length; i++) {
         let translation = translations[i]
         let newLocale = true

         currentLocale = translation.locale

         for (let key in Object.keys(locales)) {
            if (currentLocale === output[key].locale) {
               newLocale = false
            }
         }

         if (newLocale) {
            locales[currentLocale] = lastIndex
            output[lastIndex] = {
               locale: currentLocale
            }
            lastIndex++
         }

         output[locales[translation.locale]][translation.key] = translation.value
      }

      if (!IsEmpty(output)) {
         return output
      } else {
         return []
      }
   }
}

export function DisplayAddonDescription(bundle_id, addon_id, descriptions) {
   const output = []
   const locales = {}
   let lastIndex = 0
   let lastLocale = null
   let currentLocale = null

   for (let i = 0; i < descriptions.length; i++) {
      let description = descriptions[i]
      if (description.bundle_id === bundle_id && description.addon_id === addon_id) {
         if (description.description != null) {
            currentLocale = description.locale

            if (currentLocale !== lastLocale) {
               locales[currentLocale] = lastIndex
               output[lastIndex] = {
                  addon_id: description.addon_id,
                  locale: currentLocale
               }
               lastLocale = currentLocale
               lastIndex++
            }
            output[locales[description.locale]].description = description.description
         }
      }
   }

   if (!IsEmpty(output)) {
      return ({
         translations: output
      })
   } else {
      return null
   }
}

export function FetchObject(arr, id, key = 'id') {
   let output = {}

   arr.forEach((item) => {
      if (String(item[key]) === String(id)) {
         output = item
      }
   })

   return output
}
