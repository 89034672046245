// import { AjaxGet } from '../../functions'
import { i18n, loadLanguageAsync } from '../../i18n'

const language = {
   namespaced: true,
   state: () => ({
      currentLanguage: window.navigator.language.slice(0, 2) || 'da'
      /*activeLanguages: [],
      stringTranslations: []*/
   }),
   mutations: {
      set_current_language(state, language) {
         i18n.locale = language
         state.currentLanguage = language
      }
      /*set_active_languages(state, array) {
         state.activeLanguages = array
      },
      set_string_translations(state, translations) {
         state.stringTranslations = translations
      }*/
   },
   actions: {
      /*async fetch_active_languages({ commit }) {
         AjaxGet('/global/languages', (resp) => {
            const languages = []
            const translations = []

            resp.data.forEach((lang) => {
               languages.push({
                  name: lang.label,
                  locale: lang.locale,
                  flag: lang.flag
               })
               translations.push({
                  locale: lang.locale,
                  messages: lang.messages
               })
            })

            translations.forEach(test => {
               i18n.setLocaleMessage(test.locale, test.messages)
            })

            commit('set_active_languages', languages)
            commit('set_string_translations', translations)
         })
      },*/
      change_current_language({ commit }, locale) {
         commit('set_current_language', locale)
         loadLanguageAsync(locale)
      }
   }
}

export default language