const navigation = {
   namespaced: true,
   state: () => ({
      isTransparent: false
   }),
   mutations: {
      set_transparent_navigation(state) {
         state.isTransparent = true
      },
      set_solid_navigation(state) {
         state.isTransparent = false
      }
   }
}

export default navigation