<template>
   <i
      aria-hidden="true"
      :class="iconClasses"
   />
</template>

<script>
   export default {
      props: {
         icon: {
            type: Array,
            required: true
         }
      },
      computed: {
         iconClasses() {
            const classes = [...this.icon]
            classes[1] = `fa-${classes[1]}`
            classes.push('fa-icon')
            return classes
         }
      }
   }
</script>