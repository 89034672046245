import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

import alert from './modules/alert'
import auth from './modules/auth'
import language from './modules/language'
import navigation from './modules/navigation'
import settings from './modules/settings'
import storage from './modules/storage'
import theme from './modules/theme'
import createPersistedState from 'vuex-persistedstate'

const store = new Vuex.Store({
   namespaced: true,
   modules: {
      alert,
      auth,
      language,
      navigation,
      settings,
      storage,
      theme
   },
   plugins: [
      createPersistedState({
         paths: [
            'auth.token',
            'auth.user',
            'auth.ttl',
            'language.currentLanguage',
            'theme.currentTheme',
            'settings.settings'
         ]
      })
   ],
   strict: debug
})

export default store